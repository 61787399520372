<template>
  <div>
    <portal to="page-top-title">Edit CategoryGroup #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'categoryGroups-single', params: { id: id } }" icon="fa-eye" text>Back to Group</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="categoryGroups-list"
              :url="`/category-groups/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import Form from '@/modules/utils/Form'

export default {
  name: 'CategoryGroupsEdit',
  metaInfo () {
    return { title: `CategoryGroup #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput,
    FormGroupCheck
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      formData: {
        name: Form.types.input(),
        active: Form.types.boolean()
      }
    }
  },
  created () {
    this.loadEntity()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/category-groups/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.active.value = data.active

          this.loaded = true
        })
    }
  }
}
</script>
